import { Box, Center, Container, Heading, Text } from '@chakra-ui/react';
import { SEO } from '@feature/seo';
import { FC } from 'react';

export const Head = () => <SEO title='Page not found' />;

const NotFoundPage: FC = () => (
  <Box as='section' h='100vh'>
    <Center h='100%'>
      <Container centerContent>
        <Heading as='h1' mb='4'>
          Oops page not found
        </Heading>

        <Text fontSize='xl'>You just hit a dead end... sad.</Text>
      </Container>
    </Center>
  </Box>
);

export default NotFoundPage;
